body {
    background: url(/static/img/bg-lg.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#remember {
    width: 2em;
    height: 1.5em;
    margin: 0;
}
#remember-me {
    display: inline-block;
}


.panel-default {
    background-color: rgba(23, 18, 50, 0.8);
    margin-top: 1em;    

    label {
        color: white;
    }
    
    h3 {
        color: white;
    }

    .panel-footer {
        margin-bottom: 1em;
    }

    form {
        ul {
            padding-left: 1em;
            margin-bottom: 0;
            
            li {
                color: red;
            }
        }

        .row {
            margin-bottom: 0;
        }
    }

}

.alert-block {
    text-align: center;
    color: white;
}

@media (max-width: 768px){
    body {
        background-image: url("/static/img/bg-md.jpg");
    }
}

@media (max-width: 360px) {
    body {
        background-image: url("/static/img/bg-sm.jpg");
    }
}
